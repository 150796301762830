<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/caracteristica-pedido"
  >
    <FormField
      label="Nome"
      v-model="form.nome"
      :validation="$v.form.nome"
      @blur="onBlurName"
    />
    <FormField
      label="Nome interno"
      v-model="form.nome_interno"
      :validation="$v.form.nome_interno"
      v-stringscaped
    />
    <FormSelect label="Tipo" v-model="form.tipo" :options="types" />
    <FormField label="Ordenação" v-model="form.ordenacao" :money="numberMask" />
    <FormSwitch
      v-if="!isBoolean"
      v-model="form.obrigatorio"
      label="O campo é obrigatório"
    />
    <FormSwitch v-model="form.ativo" label="Exibir campo no carrinho" />
    <FormSwitch
      v-model="form.permite_alteracao_erp"
      label="Permitir que a integração desvincule pedidos desta característica"
    />
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormSwitch from '@/components/form/FormSwitch'
import FormField from '@/components/form/FormField'
import { required } from 'vuelidate/lib/validators'
import SidePopup from '@/components/SidePopup'
import {
  CARACTERISTICA_PEDIDO_LOAD,
  CARACTERISTICA_PEDIDO_CREATE,
  CARACTERISTICA_PEDIDO_UPDATE
} from '@/store/actions/caracteristicaPedido'
import StringUtils from '../../../../utils/stringUtils'
export default {
  name: 'caracteristicaPedidoForm',
  components: {
    SidePopup,
    FormField,
    FormButton,
    FormSwitch,
    FormSelect
  },
  validations: {
    form: {
      nome: { required },
      nome_interno: { required }
    }
  },
  data: () => ({
    mode: 'add',
    types: [
      {
        text: 'Texto',
        value: 'string'
      },
      {
        text: 'Booleano',
        value: 'boolean'
      }
    ],
    form: {
      nome: '',
      nome_interno: '',
      permite_alteracao_erp: true,
      obrigatorio: false,
      ativo: true,
      ordenacao: null,
      tipo: 'string'
    },
    numberMask: {
      decimal: ',',
      thousands: ' ',
      prefix: '',
      suffix: '',
      default: '',
      precision: 0,
      masked: false
    },
    isLoading: false
  }),
  methods: {
    onBlurName() {
      if (!this.form.nome_interno) {
        this.form.nome_interno = StringUtils.normalizeUrl(this.form.nome)
      }
    },
    getData(id) {
      this.isLoading = true
      this.$store
        .dispatch(CARACTERISTICA_PEDIDO_LOAD, id)
        .then(resp => {
          this.isLoading = false
          this.form = resp
        })
        .catch(() => this.onClose(true))
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.isLoading = true
      if (this.mode == 'add') {
        this.$store
          .dispatch(CARACTERISTICA_PEDIDO_CREATE, this.form)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Característica cadastrado com sucesso'
            )
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      } else {
        this.$store
          .dispatch(CARACTERISTICA_PEDIDO_UPDATE, this.form)
          .then(() => {
            this.$vueOnToast.pop(
              'success',
              'Característica atualizado com sucesso'
            )
            this.onClose(true)
          })
          .catch(() => (this.isLoading = false))
      }
    },
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/caracteristica-pedido')
      }
    }
  },
  computed: {
    title() {
      return this.mode == 'edit'
        ? 'Editar característica de pedido'
        : 'Nova característica de pedido'
    },
    isBoolean() {
      return this.form.tipo === 'boolean'
    }
  },
  created() {
    let idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.editable = true
    }
  }
}
</script>
